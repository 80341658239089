<template>
  <div class="content">
    <div class="loading" v-if="loading">拼命加载中...</div>
    <div v-else>
      <div class="html" v-html="html"></div>
    </div>
  </div>
</template>

<script>
import * as api from "@/apis/api";
export default {
  data() {
    return {
      loading: true,
      html: "",
    };
  },
  created() {
    this.loadDetail();
  },
  methods: {
    loadDetail() {
      this.loading = true;
      api.privacyPolicy({}).then((res) => {
        this.html = res;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.content {
  padding: 15px;
  background: #fff;
}
.loading {
  text-align: center;
  margin: 20% 0;
  color: #ccc;
}
</style>